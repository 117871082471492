// About page (/personal)

.link-to {
  position: relative;
  top: -4.5em;
}

.link-container h4 {
  text-decoration:none;
  border-bottom: none;
  display: inline-flex;

  a {
      padding: 0 1em;
      margin: 0;
  }
}

.markdown {

  p {
    margin: auto;
  }

  h1 {
    font-size: .8em;
    margin-top: 3em;
  }
}
