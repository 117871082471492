///
/// Future Imperfect by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Footer */

	.footer {
		padding-top: 2em;
		background-color: rgb(60, 60, 60);
		overflow: auto; 
		text-align: center;
		p {
			color: rgb(170, 170, 170);
		}
		.icons {
			color: _palette(fg-light);
		}

		.copyright {
			color: _palette(fg-light);
			font-family: _font(family-heading);
			font-size: 0.5em;
			font-weight: _font(weight-heading);
			letter-spacing: _font(kerning-heading);
			text-transform: uppercase;
		}

		body.single & {
			text-align: center;
		}
	}